
import react from 'react'
import { useState, useEffect} from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import EventWindow from './eventWindow.js'
import CreateEvent from './createEvent'
import InfoBox from './infoBox.js'
import Filter from './filter.js'
import Modal from './modal.js'
import TopBar from './topbar.js'
import UpdateEvent from './updateEvent.js';

const Calendar = ({
    getAuthToken, 
    getData, 
    filteredEvents, 
    selected, 
    activeEvent, 
    setActiveEvent, 
    setSelected, 
    activeEventType, 
    events, 
    year, 
    setYear})=> {
  const [hovered, setHovered] = useState(0)
  let dyear = 2021

  const createCalendar = () => {
    let weekObj = {}
    for (let i=1; i<=52; i++) {
      weekObj[i]=[]
    }
    let yearEvents = filteredEvents.filter(elem => elem.year===year)
    yearEvents.forEach(e => {
      for (let j = e.start; j<=e.last; j++) {
        weekObj[j].push(e)
      }
    })
    let reserved = []

    yearEvents.forEach(event => {
      let arr = [...Array(event.last+1).keys()].map(elem => {if (elem>=event.start){return [elem, event.size]}})
      reserved = reserved.concat(arr.filter(element => {return (element!==undefined)}))
    })

    function handleClick(arg) {

      let hoveredEvent = filteredEvents.filter(elem => { 
        return (hovered>=elem.start&&hovered<=elem.last&&year===elem.year)
    })[0]
    let newSelected = selected.map(function(e){return e})



    if ((weekObj[arg].length===1&&weekObj[arg][0].size==="1/1")||weekObj[arg].length===2) {
      if (!selected.includes(arg)) {
        setSelected([arg])
        let copyArray = weekObj[arg]

        setActiveEvent(copyArray)
      }
       else {
        setActiveEvent([])
        setSelected([])
      }
    }
    // else if (weekObj[arg].length===1) {
    //   if (!selected.includes(arg)) {}
    //   setActiveEvent(weekObj[arg])
    //   newSelected.push(arg)
    //   setSelected(newSelected)
    // }
    else {
      if (weekObj[arg].length===1 && weekObj[arg][0].size==="1/2") {
        setActiveEvent(weekObj[arg])
      }
      if (activeEvent.length>0 && (activeEvent.length===2 || activeEvent[0].size==="1/1")) {
        if (weekObj[arg].length===0) {
          setActiveEvent([])
        }
        newSelected=[arg]
      }      
      else if (!newSelected.includes(arg)) {
        console.log("here")
        newSelected.push(arg)
        console.log(weekObj[arg])
        if (weekObj[arg].length>0) setActiveEvent(weekObj[arg])
      }
      else newSelected.splice(newSelected.indexOf(arg), 1)
      if (newSelected.filter(elem=>weekObj[elem].length>0).length===0) {
        setActiveEvent([])
      }
      setSelected(newSelected)
      }
    }

    let days = []
    for (let i = 1; i<=52; i++) {
      days.push(i)
    }

    let weeks = days.map(week => {
      let style={}
      if (selected.includes(week)) {style={backgroundColor: "black", color: "white"}}

      if (reserved.some(elem => elem[0]===week)) {

        // let obj = reserved.find(element => element[0]===week)
        if ((weekObj[week].length===1&&weekObj[week][0].size==="1/1")) {style["backgroundColor"]="red"}
        else if (weekObj[week].length===1 && weekObj[week][0].size==="1/2" && selected.includes(week)) {
          style["background"]= "linear-gradient(to top, red 0%, red 50%, black 50%, black 100%)"
        }
        else if (selected.includes(week) && weekObj[week].length===2) {
          style["background"]= "linear-gradient(to top, red 0%, red 35%, black 35%, black 65%, red 65%, red 100%)"
        }
        else if (weekObj[week].length===2) {
          style["background"]= "linear-gradient(to top, red 0%, red 35%, white 35%, white 65%, red 65%, red 100%)"
        }
        else style["background"]= "linear-gradient(to top, red 0%, red 50%, white 50%, white 100%)"

      }

      return <h3 key={week} className="calendar-week" style={style} onClick={()=>handleClick(week)}
      onMouseOver={()=>setHovered(week)}>{week}</h3>
    })
    let yearBorderOffset = `${(year-2022)*62}px`
    return <div>
    <div className="years-container">
<div className="active-year-border" style={{left: yearBorderOffset}}></div>
<h3 onClick={()=>changeYear(2022)}>2022</h3>
<h3 onClick={()=>changeYear(2023)}>2023</h3>
<h3 onClick={()=>changeYear(2024)}>2024</h3>
</div>
    <div className="weeks">
      {weeks}
      </div>
      </div>
  }


  function changeYear(amount){
    setYear(amount)
    setActiveEvent({})
    setSelected([])
  }


  let calendar = createCalendar()
  return (
    <div className="calendar-wrapper">
        <div className="calendar">
            {calendar}
        </div>
      </div>
  );
}
export default Calendar